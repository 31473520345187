/* eslint-disable eqeqeq */
import { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import * as formik from 'formik'
import * as yup from 'yup';
import "yup-phone-lite";
import { t } from 'i18next'
import Cookies from "js-cookie";
import { removeCart } from '../../slices/cartSlice'
import { appService } from '../../services/app.service'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { FloatingLabel, Form as BootstrapForm } from 'react-bootstrap'
import Toast from '../../components/Toast/Toast'
import { Spinner } from "react-bootstrap";
import country from "country-code-flag-phone-extension-json";
import Select from "react-select";


export default function AddOrderModal(props) {
  const { id, type } = useParams();
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { Formik } = formik
  const { items } = useSelector((state) => state.cart)
  const [selectedCountryCode, setSelectedCountryCode] = useState({})
  const [countries, setCountries] = useState([])
  const [selectedOptions, setSelectedOptions] = useState();
  const validationSchema = yup.object().shape({
    customerName: yup.string().required(t('nameReq')),
    address: yup.string().when('type', {
      is: (x) => x == 'delivery',
      then: yup.string().required(t('addressReq')),
    }),
    notes: yup.string().nullable(),
    phone: yup.string().required(),
  })

  useEffect(() => {
    setCountries(country.all().rows)
  }, [countries])

  function handleSelect(data) {
    setSelectedOptions(data);
    const option = countries.find(opt => opt.id == data.id);
    setSelectedCountryCode(option)
  }

  const optionList = []
  // console.log(countries)
  countries?.forEach((code) => {
    optionList.push({
      id: code.id,
      value: code.dail_code,
      label: code?.dail_code + '       ' + code?.emoji
    })
  })

  const handleSubmit = async (values, { setSubmitting }) => {
    let cartForOrder = []
    items.forEach(item => {
      cartForOrder.push({
        product_id: item.id,
        quantity: item.quantity,
        notes: item.note
      })
    });
    const data = {
      customer_name: values.customerName,
      phone: parseInt('964') + values.phone.replace(/^0+/, ''),
      name: type === 'delivery' ? null : type,
      notes: values.notes,
      store_id: id,
      items: cartForOrder,
      address: values.address,
    };
    let res = await appService.addOrder(data);
    if (res.status == 200) {
      dispatch(removeCart())
      Toast.SuccessToast(t('orderedSuccess'))
      type === 'delivery' ? navigate(`/${id}/delivery`) : navigate(`/${id}/${type}`)
      // fix it saraaaaaaaaaaaa
      // fix it saraaaaaaaaaaaa
      // fix it saraaaaaaaaaaaa
      // fix it saraaaaaaaaaaaa
      // fix it saraaaaaaaaaaaa
      // fix it saraaaaaaaaaaaa
      // fix it saraaaaaaaaaaaa
      Cookies.set("orderId", JSON.stringify(res.data.items[0].order_id));
      props.onHide()
    }
    else {
      Toast.ErrorToast(res?.data.message ?? 'error')
    }
    setSubmitting(false)
  }

  return (
    <Modal {...props} size="lg" centered>
      <Modal.Header>
        <Modal.Title>{t('sendOrder')}</Modal.Title>
      </Modal.Header>
      <Formik initialValues={{
        customerName: '',
        phone: '',
        address: '',
        notes: ''
      }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}>
        {({ handleChange, values, touched, errors, isSubmitting }) => (
          <formik.Form>
            <Modal.Body>
              <div className='col-lg-12'>
                <FloatingLabel controlId="customerName" label={t('customerName') + '*'} className={'mb-3'}>
                  <BootstrapForm.Control type="text" name="customerName" value={values.customerName}
                    onChange={handleChange} isValid={touched.customerName && !errors.customerName} isInvalid={touched.customerName && errors.customerName} />
                  <BootstrapForm.Control.Feedback type="invalid">
                    {errors.customerName}
                  </BootstrapForm.Control.Feedback>
                </FloatingLabel>
              </div>
              <div className='col-lg-12'>
                {/* <div className='row'>
                  <div className='col-4 pe-0'>
                    <FloatingLabel controlId="country">
                      <Select
                        options={optionList}
                        onChange={handleSelect}
                        value={selectedOptions}
                        isSearchable={true}
                        placeholder='+964'
                      />
                    </FloatingLabel>
                  </div>
                  <div className='col-8 ps-0'> */}

                <FloatingLabel controlId="phone" label={t('phone') + '*'} className={'mb-3 '}>
                  <BootstrapForm.Control type="text" name="phone" value={values.phone}
                    onChange={handleChange} isValid={touched.phone && !errors.phone} isInvalid={touched.phone && errors.phone} />
                  <BootstrapForm.Control.Feedback type="invalid">
                    {errors.phone}
                  </BootstrapForm.Control.Feedback>
                </FloatingLabel>
                {/* </div>
                </div> */}
              </div>
              {
                type !== 'delivery' ? <></> :
                  <div className='col-lg-12'>
                    <FloatingLabel controlId="address" label={t('address')} className={'mb-3'}>
                      <BootstrapForm.Control as="textarea" style={{ height: '100px' }} type="text" name="address" value={values.address}
                        onChange={handleChange} isValid={touched.address && !errors.address} isInvalid={touched.address && errors.address} />
                      <BootstrapForm.Control.Feedback type="invalid">
                        {errors.address}
                      </BootstrapForm.Control.Feedback>
                    </FloatingLabel>
                  </div>
              }

              <div className='col-lg-12'>
                <FloatingLabel controlId="notes" label={"أدخل وقت التسليم"} className={'mb-3'}>
                  <BootstrapForm.Control as="textarea" style={{ height: '100px' }} type="text" name="notes" placeholder="أدخل تفاصيل الكمية" value={values.notes}
                    onChange={handleChange} isValid={touched.notes && !errors.notes} isInvalid={touched.notes && errors.notes} />
                  <BootstrapForm.Control.Feedback type="invalid">
                    {errors.notes}
                  </BootstrapForm.Control.Feedback>
                </FloatingLabel>
              </div>

            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={props.onHide}>
                {t('close')}
              </Button>
              <Button variant="primary" disabled={isSubmitting} type="submit">{isSubmitting ? <Spinner size="sm" /> : t('send')}</Button>
            </Modal.Footer>
          </formik.Form>
        )}
      </Formik>
    </Modal>
  );
}

