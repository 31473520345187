import { createAsyncThunk } from "@reduxjs/toolkit";
import { axiosService } from "./axios.service"

const getStoreInfo = createAsyncThunk('store/get', async ({ id, type }) => {
    const res = await axiosService.get(type ? `store/get/${id}/${type}` : `store/get/${id}`)
    const data = {
        id: res.data.data.id,
        name: res.data.data.name,
        sentEmail: res.data.data.sent_email,
        address: res.data.data?.address ?? null,
        latitude: res.data.data?.latitude ?? null,
        longitude: res.data.data?.longitude ?? null,
        phone: res.data.data.phone,
        isActive: res.data.data.is_active,
        theme: res.data.data?.theme ? JSON.parse(res.data.data.theme) : undefined,
        receiveMessage: res.data.data.receive_message ?? null,
        image: res.data.data?.image ?? null,
        hiddenPrice: res.data.data.hidden_price,
        lang: res.data.data.lang,
        subscription: {
            id: res.data.data.subscription.id,
            expiryDate: res.data.data.subscription.expiry_date,
            activatorId: res.data.data.subscription.activator_id,
            storeId: res.data.data.subscription.store_id,
            deletedAt: res.data.data.subscription.deleted_at,
            createdAt: res.data.data.subscription.created_at,
            updatedAt: res.data.data.subscription.updated_at,
            paymentId: res.data.data.subscription.payment_id,
            isExpired: res.data.data.subscription.is_expired,
            remainingDays: res.data.data.subscription.remaining_days,
            price: res.data.data.subscription.price,
            payment: res.data.data.subscription.payment ? {
                id: res.data.data.subscription.payment.id,
                amount: res.data.data.subscription.payment.amount,
                currency: res.data.data.subscription.payment.currency,
                description: res.data.data.subscription.payment.description,
                createdAt: res.data.data.subscription.payment.created_at,
                updatedAt: res.data.data.subscription.payment.updated_at,
                payerId: res.data.data.subscription.payment.payer_id,
                payeeId: res.data.data.subscription.payment.payee_id,
            } : 0,
        },
        type: res.data.data.type,
        rate: res.data.data.rate,
        recommendations: Object.entries(res.data.data.recommendations).reduce(
            (acc, [key, value]) => {
                acc[key] = {
                    id: value.id,
                    name: value.name,
                    description: value.description,
                    image: value.image,
                    recommendation: value.recommendation,
                    price: value.price,
                    discountedPrice: value.discounted_price,
                    storeId: value.store_id,
                    categoryId: value.category_id,
                    deletedAt: value.deleted_at,
                    createdAt: value.created_at,
                    updatedAt: value.updated_at,
                    orderCount: value.order_count,
                    rate: value.rate,
                    hasDiscount: value.has_discount,
                };
                return acc;
            },
            {}
        ),
        categories: res.data.data.categories.map((category) => ({
            id: category.id,
            name: category.name,
            description: category.description,
            image: category.image,
            storeId: category.store_id,
            deletedAt: category.deleted_at,
            createdAt: category.created_at,
            updatedAt: category.updated_at,
            productCount: category.product_count,
        })),
    };
    return data
});

const getCategories = createAsyncThunk('category/get', async (id) => {
    const res = await axiosService.get(`category/get/${id}`)
    const data = {
        id: res.data.data.id,
        name: res.data.data.name,
        description: res.data.data.description,
        image: res.data.data.image,
        store_id: res.data.data.store_id,
        deletedAt: res.data.data.deleted_at,
        createdAt: res.data.data.created_at,
        updatedAt: res.data.data.updated_at,
        productCount: res.data.data.product_count,
        products: res.data.data.products.map((product) => ({
            id: product.id,
            name: product.name,
            description: product.description,
            image: product.image,
            recommendation: product.recommendation,
            price: product.price,
            discountedPrice: product.discounted_price,
            storeId: product.store_id,
            categoryId: product.category_id,
            deletedAt: product.deleted_at,
            createdAt: product.created_at,
            updatedAt: product.updated_at,
            orderCount: product.order_count,
            rate: product.rate,
            hasDiscount: product.has_discount
        }))
    };
    return data
})

const getSearchedProducts = createAsyncThunk('/product/get', async ({ id, dataToSend }) => {
    const res = await axiosService.get(`/product/get/${id}?search=${dataToSend}`)
    const data = {
        products: res.data.data.map((product) => ({
            id: product.id,
            name: product.name,
            description: product.description,
            image: product.image,
            recommendation: product.recommendation,
            price: product.price,
            discountedPrice: product.discounted_price,
            storeId: product.store_id,
            categoryId: product.category_id,
            deletedAt: product.deleted_at,
            createdAt: product.created_at,
            updatedAt: product.updated_at,
            orderCount: product.order_count,
            rate: product.rate,
            hasDiscount: product.has_discount
        }))
    };
    return data
})


const addOrder = async (dataToSend) => {
    let res = await axiosService.post(`order/add`, dataToSend);
    return res
}

const getStoreRateServices = createAsyncThunk('rate/services/', async (id) => {
    let res = await axiosService.get(`rate/services/${id}`);
    const data = res.data
    return data
})

const addRate = async (dataToSend) => {
    let res = await axiosService.post(`rate/store`, dataToSend);
    return res
}

export const appService = {
    getStoreInfo, getCategories, addOrder, getStoreRateServices, addRate, getSearchedProducts
}