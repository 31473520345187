/* eslint-disable react-hooks/exhaustive-deps */
import { useState } from 'react';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import Card from 'react-bootstrap/Card';
import productImg from '../../assets/imgs/logo.png';
import { t } from 'i18next'
import { appService } from "../../services/app.service";
import ReactStars from "react-rating-stars-component";
import AddItemToCartModal from '../modals/AddItemToCartModal';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import env from '../../env';
import { setSelectedProduct } from "../../slices/cartSlice";
import { selectCategory, selectCategoryLoading } from '../../slices/categorySlice';
import FullScreenLoading from '../Loading/FullScreenLoading';

function Product() {
  const dispatch = useDispatch();
  const categoryDataWithItProducts = useSelector(selectCategory);
  const isLoading = useSelector(selectCategoryLoading);
  const [show, setShow] = useState(false);
  const { selectedCategoryId } = useSelector((state) => state.helperSlice);


  const checkCartItem = (product) => {
    dispatch(setSelectedProduct(product))
  };

  useEffect(() => {
    if (selectedCategoryId) {
      dispatch(appService.getCategories(selectedCategoryId));
    }
  }, [selectedCategoryId]);

  return (

    <div className='position-relative pt-4 ps-1 product'>
      {isLoading ? <FullScreenLoading height='300px' paddingTop='100px' /> :
        categoryDataWithItProducts?.products.length > 0 ? (
          <>
            <div style={{ width: '200px', height: '100px', borderColor: 'var(--second-color) !important' }} className='position-absolute products-border' ></div>
            <h2 className='position-absolute category-title1 '  >{t('product')}</h2>
            <h2 className='position-absolute category-title2  '>{categoryDataWithItProducts?.name}</h2>
            <Swiper
              className='w-85 my-3 m-auto py-3'
              modules={[Navigation, Pagination, Scrollbar, A11y]}
              spaceBetween={5}
              slidesPerView={1}
              breakpoints={{
                0: {
                    slidesPerView: 1,
                },
                600: {
                    slidesPerView: 2,
                },
                800: {
                    slidesPerView: 4,
                },
              }}
              navigation
            >
              {categoryDataWithItProducts?.products.map((item, idx) => (
                <SwiperSlide key={idx} >
                  <Card style={{ width: '9rem' }} className='position-relative overflow-hidden' onClick={() => { setShow(true); checkCartItem(item) }}>
                    {item.image ?
                    <Card.Img variant="top" src={env.API_LINK_STORAGE + item.image} /> :
                    <Card.Img  src={productImg} style={{width:'50% !important ' , matgin :'auto'}} />
                    }
                    <Card.Body className='px-1'>
                      <Card.Title className='text-center'>{item.name}</Card.Title>
                      <Card.Text className='text-Secondary m-0 product-desc text-center' >{item.description}</Card.Text>
                    </Card.Body>
                    <Card.Body className='d-flex position-relative p-0 flex-row justify-content-around px-1'>
                      <Card.Text style={{ width: 'fit-content' }} className='m-0'>
                        <ReactStars
                          style={{ width: 'fit-content' }}
                          count={5}
                          size={20}
                          value={item?.rate}
                          activeColor='var(--main-color)'
                        />
                      </Card.Text>
                      {item.price ?
                        item.discountedPrice ? (
                          <div>
                            <Card.Text className='fs-6'>{item.discountedPrice}</Card.Text>
                            <Card.Text className='position-absolute text-decoration-line-through text-danger des-price' style={{ top: '-12px' }} >{item.price}</Card.Text>
                          </div>
                        ) : (
                          <Card.Text >{item.price}</Card.Text>
                        ) : <></>}
                    </Card.Body>
                    {item.discountedPrice ?
                      <Card.Body className='position-absolute bg-success bg-gradient p-0 px-5' style={{ top: '13px', left: '-44px', transform: 'rotate(-45deg)' }}>
                        <Card.Text href="#" className='text-light fs-2' style={{}} >{t('offer')}</Card.Text>
                      </Card.Body> :
                      <></>}
                  </Card>
                  <AddItemToCartModal
                    show={show}
                    onHide={() => setShow(false)}
                  />
                </SwiperSlide>
              ))}
            </Swiper>
          </>
        ) : <></>
      }
    </div>
  );
}

export default Product;
