import { useTranslation } from 'react-i18next';
import Form from 'react-bootstrap/Form';

const LanguageSwitcher = () => {
    const { i18n } = useTranslation();

    const handleLanguageChange = (e) => {
        const selectedLanguage = e.target.value;
        i18n.changeLanguage(selectedLanguage);
        window.location.reload()
    };

    const languageOptions = Object.keys(i18n.services.resourceStore.data).map((language) => (
        <option value={language} key={language} className='text-center'>
            {i18n.t('languageName', { lng: language })}
        </option>
    ));

    return (
        <Form.Select aria-label='Default select example' onChange={handleLanguageChange} defaultValue={i18n.language}>
            {languageOptions}
        </Form.Select>
    );
};

export default LanguageSwitcher;