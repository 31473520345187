import React from 'react'
import MainLayout from '../components/layouts/MainLayout'
import Category from '../components/pageSections/Category'
import Product from '../components/pageSections/Products'
import Recommended from '../components/pageSections/Recommended'
import Search from '../components/pageSections/Search'

export default function HomePage() {
    return (
        <MainLayout>
            {/* <Search /> */}
            <Category />
            <Product />
            <Recommended />
        </MainLayout>
    )
}
