import { createApiSlice } from '../helpers/createApiSlice';
import { appService } from '../services/app.service';

const rateSlice = createApiSlice({
    name: 'rate',
    initialState: {
        data: null,
        isLoading: false,
        error: null,
    },
    fetchThunk: appService.getStoreRateServices,
});

export const selectRate = (state) => state.rate.data;
export const selectRateLoading = (state) => state.rate.isLoading;
export const selectRateInfoError = (state) => state.rate.error;
export default rateSlice.reducer;