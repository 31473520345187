import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import i18n from "../../helpers/i18n";

const ErrorToast = (message) => {
  toast.error(message, {
    position: "top-center",
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
    rtl: i18n.language === "ar" ? true : false,
  });
};

const SuccessToast = (message) => {
  toast.success(message, {
    position: "top-center",
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
    rtl: i18n.language === "ar" ? true : false,
  });
};

const Toast = {
  ErrorToast,
  SuccessToast,
};

export default Toast;
