import ErrorPage from "./pages/errors/ErrorPage";
import HomePage from "./pages/HomePage";
import Error404Page from "./pages/errors/Error404Page";
import CartPage from "./pages/CartPage";
import RateStorePage from './pages/RateStorePage'
import StoreInfoPage from "./pages/StoreInfoPage";
import { Route, Routes } from "react-router-dom";

function App() {
  return (
    <Routes>
      <Route index element={<ErrorPage />} />
      <Route path="/">
        <Route path=":id/:type/">
          <Route index element={<HomePage />} />
          <Route path="store-details" element={<StoreInfoPage />} />
          <Route path="cart" element={<CartPage />} />
        </Route>
        <Route path=":id/rate" element={<RateStorePage />} />
        <Route path="*" element={<Error404Page />} />
      </Route>
      <Route path="/">
        <Route path=":id/">
          <Route index element={<HomePage />} />
          <Route path="store-details" element={<StoreInfoPage />} />
          <Route path="cart" element={<CartPage />} />
        </Route>
        <Route path=":id/rate" element={<RateStorePage />} />
        <Route path="*" element={<Error404Page />} />
      </Route>
    </Routes>
  );
}

export default App;
