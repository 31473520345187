import { createApiSlice } from '../helpers/createApiSlice';
import { appService } from '../services/app.service';

const storeInfoSlice = createApiSlice({
    name: 'storeInfo',
    initialState: {
        data: null,
        isLoading: false,
        error: null,
    },
    fetchThunk: appService.getStoreInfo,
});

export const selectStoreInfo = (state) => state.storeInfo.data;
export const selectStoreInfoLoading = (state) => state.storeInfo.isLoading;
export const selectStoreInfoInfoError = (state) => state.storeInfo.error;

export default storeInfoSlice.reducer;