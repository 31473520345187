/* eslint-disable react-hooks/exhaustive-deps */
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import Card from 'react-bootstrap/Card';
import categoryImg from '../../assets/imgs/coffee.png';
import { useDispatch, useSelector } from 'react-redux';
import env from '../../env';
import { t } from 'i18next'
import { selectStoreInfo } from '../../slices/storeInfoSlice';
import { setSelectedCategoryId } from '../../slices/helperSlice';
import { useEffect } from 'react';

function Category() {
  const storeInfo = useSelector(selectStoreInfo);
  const dispatch = useDispatch();
  const { selectedCategoryId } = useSelector((state) => state.helperSlice);
  function handelCategoryClick(id) {
    dispatch(setSelectedCategoryId(id))
  }

  useEffect(() => {
    if (storeInfo?.categories.length > 0)
      handelCategoryClick(storeInfo?.categories[0].id);
  }, []);

  return (
    <section className='mt-3'>
      <h2>{t('categories')}</h2>
      {storeInfo?.categories.length > 0 ? (
        <Swiper
          className='my-3'
          modules={[Navigation, Pagination, Scrollbar, A11y]}
          spaceBetween={10}
          slidesPerView={3}
          navigation
        >
          {storeInfo?.categories.map((item, idx) => (
            <SwiperSlide key={idx} onClick={() => handelCategoryClick(item.id)} style={{ minWidth: '60px' }}>
              <Card style={{ width: '100px', minHeight: '100px', height:'fit-content' }} className={` category-card ${selectedCategoryId === item.id ? "bg-main text-white" : ""}`}>
                <Card.Img variant="top" src={item?.image ? env.API_LINK_STORAGE + item?.image : categoryImg} style={{width:'60px', height:'60px'}} className='rounded m-auto' />
                <Card.Text className='text-center'>{item.name}</Card.Text>
              </Card>
            </SwiperSlide>
          ))}
        </Swiper>) : (
        <div>{t('noCategory')}</div>
      )}
    </section>

  );
}

export default Category;
