import { createSlice } from '@reduxjs/toolkit';

export const createApiSlice = (options) => {
    const { name, initialState, fetchThunk } = options;

    const slice = createSlice({
        name,
        initialState,
        reducers: {},
        extraReducers: (builder) => {
            builder
                .addCase(fetchThunk.pending, (state) => {
                    state.isLoading = true;
                    state.error = null;
                })
                .addCase(fetchThunk.fulfilled, (state, action) => {
                    state.isLoading = false;
                    state.data = action.payload;
                })
                .addCase(fetchThunk.rejected, (state, action) => {
                    state.isLoading = false;
                    state.error = action.error.message;
                });
        },
    });

    return slice;
};