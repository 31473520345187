/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import headImg from '../assets/imgs/logo.png';
import cartImg from '../assets/imgs/cartImage.gif';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import CartLayout from '../components/layouts/CartLayout';
import AddOrderModal from '../components/modals/AddOrderModal';
import DeleteModal from '../components/modals/DeleteModal';
import { t } from 'i18next'
import { useDispatch, useSelector } from 'react-redux';
import { getAll, decreaseOneToCart, increaseOneToCart, setDeleteProduct } from "../slices/cartSlice";
import env from '../env';
import { Link, useParams } from 'react-router-dom';
import { selectStoreInfo } from "../slices/storeInfoSlice";
import MainLayout from '../components/layouts/MainLayout';

export default function CartPage() {

    const { id, type } = useParams();
    const dispatch = useDispatch();
    const { items, totalAmount, deleteProduct } = useSelector((state) => state.cart);
    const [show, setShow] = useState(false);
    const [modalShow, setModalShow] = React.useState(false);
    const storeInfo = useSelector(selectStoreInfo);


    const increaseOneClicked = (product) => {
        dispatch(increaseOneToCart(product));
    };

    const decreaseOneClicked = (product) => {
        dispatch(decreaseOneToCart(product));
    };

    useEffect(() => {
        dispatch(getAll())
    }, [totalAmount, deleteProduct]);

    return (
        <>
            {items.length > 0 ? (
                <MainLayout>
                    <Card className='border-0'>
                        <Card.Body className='text-center'>
                            <Card.Title className='fs-1 mb-3'>{t('cartTitle')}</Card.Title>
                            <Card.Img className="w-25" src={cartImg} />
                        </Card.Body>
                        {items?.map((item, idx) => (
                            <Card.Body key={idx} className='d-flex flex-row flex-wrap justify-content-between items-center border-bottom' style={{ alignItems: 'center' }}>
                                <div className='d-flex flex-row flex-wrap justify-content-around items-center' style={{ alignItems: 'center' }}>
                                    <Card.Img className='m-1' style={{ width: '50px', height: '50px' }} src={item.image ? env.API_LINK_STORAGE + item.image : headImg} />
                                    <div>
                                        <Card.Title className='m-0'>{item.name}</Card.Title>
                                        <Card.Text>{item.note}</Card.Text>
                                    </div>
                                </div>
                                <div>
                                    <Button className='p-0 border-0 d-block' variant='outline-danger' style={{ marginInline: 'auto 0px' }} onClick={() => { dispatch(setDeleteProduct(item)); setModalShow(true) }}><i className="bi bi-trash3 fs-3"></i></Button>
                                    <DeleteModal
                                        show={modalShow}
                                        onHide={() => { dispatch(setDeleteProduct(item)); setModalShow(false) }}
                                    />
                                    <div className='d-flex flex-row flex-wrap justify-content-between items-center' style={{ alignItems: 'center' }}>
                                        <Button variant="outline-dark" style={{ width: '30px' }} className='p-0' onClick={() => increaseOneClicked(item)} >+</Button>
                                        <Card.Text className='m-0 mx-1 fs-6 px-2 rounded-pill text-light' style={{ backgroundColor: 'var(--main-color)' }}>{item.quantity}</Card.Text>
                                        <Button variant="outline-dark" style={{ width: '30px' }} className='p-0' onClick={() => decreaseOneClicked(item)} >-</Button>
                                    </div>
                                    <Card.Text className='text-center m-0'>{item.price * item.quantity}</Card.Text>
                                </div>
                            </Card.Body>
                        ))}
                        <Card.Body className='d-flex flex-row flex-wrap justify-content-between items-center'>
                            <Card.Title className='m-0'>{t('total')}</Card.Title>
                            <Card.Title>{totalAmount}</Card.Title>
                        </Card.Body>
                    </Card>
                    <Button variant="primary" className='m-auto d-block w-25' onClick={() => setShow(true)}>{t('order')}</Button>
                    <AddOrderModal
                        show={show}
                        onHide={() => setShow(false)}
                    />
                </MainLayout>
            ) : (
                <MainLayout>
                    <Card style={{ width: '100vw', minHeight: 'calc(100vh - 86px)' }} className='border-0'>
                        <Card.Body className='text-center'>
                            <Card.Title className='fs-1 mb-3'>{t('cartTitle')}</Card.Title>
                            <Card.Img className="w-25" src={cartImg} />
                        </Card.Body>
                        <Card.Body className='m-auto text-center pt-5'>
                            <Card.Title className=''>{t('empty')}</Card.Title>
                            <Card.Title>{t('home')}</Card.Title>
                            <Link to={type ? `/${id}/${type}` : `/${id}`}>{t('here')}</Link>
                        </Card.Body>
                    </Card>
                </MainLayout>
            )}
        </>
    )
}
