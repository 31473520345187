import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import Card from 'react-bootstrap/Card';
import recommendedImg from '../../assets/imgs/Logo2.png';
import { useDispatch, useSelector } from 'react-redux';
import env from '../../env';
import { useState } from 'react';
import { setSelectedProduct } from "../../slices/cartSlice";
import AddItemToCartModal from '../modals/AddItemToCartModal';
import { selectStoreInfo } from '../../slices/storeInfoSlice';
import { t } from 'i18next'

function Recommended() {
  const dispatch = useDispatch();
  const storeInfo = useSelector(selectStoreInfo);
  const [show, setShow] = useState(false);
  const [addedBefor, setAddedBefor] = useState(false);
  const { items } = useSelector((state) => state.cart);

  const checkCartItem = (product) => {
    dispatch(setSelectedProduct(product))
    items?.forEach((item) => {
      if (item?.id === product.id) {
        return setAddedBefor(true);
      }
    });
  };

  return (
    <>
      <style>
        {`
            .swiper-slide{
                margin-right:0px;
            }
            .card{
                margin-inline:auto;
            }
        `}
      </style>
      {storeInfo ? Object.entries(storeInfo?.recommendations).length > 0 ? 
      <div className='position-relative w-85 my-5 m-auto recommended d-flex flex-row justify-content-between pe-4 flex-wrap' style={{ gap: '25px', alignItems: 'center' }}>
        <div style={{ width: '200px', height: '100px', borderColor: 'var(--second-color) !important' }} className='position-absolute recommend-border' ></div>
        <h2 className='position-absolute recommend-title1 '  >{t('forYou')}</h2>
        <h2 className='position-absolute recommend-title2  '>{t('recommendedTitle')}</h2>

          {Object.entries(storeInfo?.recommendations).map(([key, value]) => 
            <div key={key} style={{ width: '40%' }} className='me-0 ms-0 position-relative mb-4' >
              <Card onClick={() => { setShow(true); checkCartItem(value) }} className='recommended-box'>
                <Card.Img variant="top" src={value.image ? env.API_LINK_STORAGE + value.image : recommendedImg} className='position-absolute rounded-circle recommended-img' style={{ top: '-25px', width: '100px', height: '100px' }} />
                <Card.Body>
                  <div></div>
                </Card.Body>
                <Card.Body className='mt-3 pt-5 text-center'>
                  <Card.Title>{value.name}</Card.Title>
                </Card.Body>
              </Card>
              <AddItemToCartModal
                show={show}
                onHide={() => setShow(false)}
                addedBefor={addedBefor}
                setAddedBefor={setAddedBefor}
              />
            </div>
          )}
      </div> : <></> : <></> }
    </>
  );
}

export default Recommended;
