import { createGlobalStyle } from "styled-components";
export const ThemeColors = createGlobalStyle`

:root {
  --main-color:  ${(props) => props.theme.mainColor};
  --second-color:  ${(props) => props.theme.secondaryColor};
  --toastify-color-success: var(--main-color) !important ;
  --dir:  ${(props) => props.theme.dir};
}
html,body{
  direction:var(--dir);
  text-align:start;
  font-family:myFont
}

.bg-main
{
  background-color:var(--main-color) !important ;
}
.navbar.sticky {
  position: sticky;
  top: 0;
  z-index: 100;
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.5);
}
.input-group .btn,
.input-group  .form-control{
  border-radius: 20px;
}
.btn:hover {
  /* color: var(--main-color) !important; */
  /* background-color: var(--main-color) !important; */
  /* border-color: var(--main-color) !important; */
}

.btn-primary {
  background-color: var(--main-color) !important;
  color: white;
  border-color: var(--main-color) !important;
}
.btn-secondary{
  background-color: var(--second-color) !important;
  color: white;border-color: var(--second-color) !important;
}
.footer{
    height:50px;
    background-color:var(----main-color);
    font-family:myFont;
    display: block;
    margin-inline: auto;
    width: fit-content;
    padding-top: 10px;
}
.content{
    min-height: calc(100vh - 115px);
}
.form-control:focus
{
  color: var(----main-color);
  background-color: var(--bs-body-bg);
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 .25rem var(--main-color);
}
.swiper-wrapper{
  align-items: center;
  width: fit-content;
  margin: auto;
  justify-content: flex-start;
  padding-bottom: 20px;
  max-width: 100vw;
}
.swiper-slide {
  width: 100px;
}
.swiper-button-prev, .swiper-button-next{
  color:var(--second-color)
}
.card-text{
  /* font-size: 13px; */
}
.product-desc{
  max-height: 39px;
  font-size: 13px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.layout .form-select{
  --bs-form-select-bg-img: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27%3e%3cpath fill=%27none%27 stroke=%27%23343a40%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%272%27 d=%27m2 5 6 6 6-6%27/%3e%3c/svg%3e");
  display: block;
  width: fit-content;
  padding: 0px;
  text-align: center;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--main-color);
  appearance: none;
  background-color: transparent;
  background-image: none;
  background-repeat: no-repeat;
  background-size: 16px 12px;
  border: none;
  border-radius: var(--bs-border-radius);
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
.product .react-stars,
.store-info .react-stars,
.layout .react-stars {
  pointer-events:none;
  cursor: auto;
}
.product .react-stars{
  width:fit-content;
  margin-inline: auto;
}
.css-13cymwt-control {
  min-height: 58px;
}
.css-1nmdiq5-menu , .css-b62m3t-container{
  z-index: 10;
}
.form-floating > label {
  z-index: 0;
}
.category-card{
  transition: .3s;
}
.category-card:hover , .selected-category{
  background-color: var(--second-color);
  color:white
}

#infoSection .container {
  display: block;
  margin-inline: auto;
  min-height: 76vh;
}
#infoSection h2,
#infoSection h4 {
  margin-block: 20px;
}
.none {
  display: none;
}
.store-details {
  display: block;
  text-align: center;
}
.orderBasha-details {
  display: block;
  text-align: center;
}
.zIndex {
  position: relative;
  z-index:0;
  display: inline-block;
  border-radius: 50%;
  width: 150px;
  height: 150px;
  overflow: hidden;
  align-items: center;
  margin-inline-end: -40px;
  background-color: var(--light-color);
}
.setZIndex {
  position: relative;
  z-index: 2;
  display: inline-block;
  border-radius: 50%;
  width: 150px;
  height: 150px;
  overflow: hidden;
  align-items: center;
  margin-inline-end: -40px;
  background-color: var(--light-color);
  box-shadow: 0px 3px 9px 0px var(--second-color);
}
.images-box {
  width: 250px;
  display: block;
  margin-inline: auto;
  padding-top: 20px;
}
.orderBasha-social-media {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0px;
  padding-top: 20px;
  margin-block: 20px;
}
.store-social-media {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0px;
  padding-top: 20px;
  margin-top: 20px;
}
.store-social-media span {
  display: inline;
  margin-block: auto;
}
.store-social-media div i,
.orderBasha-social-media a {
  margin-inline: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  width: 40px;
  height: 40px;
  border-radius: 10px;
  border: 1px solid var(--main-color);
  color: var(--main-color);
  transition: 0.3s;
}
.store-social-media div:hover i,
.orderBasha-social-media a:hover {
  border: 1px solid var(--main-color);
  color: white !important;
  background: var(--main-color);
}

#RatesSection{
  margin-top: 100px;
  padding-inline: 10px;
  min-height: 76vh;
}
#RatesSection .react-stars{
  display: flex;
  position: unset;
  justify-content: end;
  margin-inline: auto 0px;
}
#RecommendedSection .recommended-offer{
  position: absolute;
  top: 0px;
  z-index: 2;
  font-weight: 700;
  left: 0px;
  width: 112px;
  height: 100px;
  overflow: hidden;
}
#RecommendedSection .recommended-offer-span{
  background-color: var(--light-color);
  color: white;
  font-weight: 700;
  left: -30px;
  transform: rotate(-45deg);
  font-size: 24px;
  width: 112px;
  position: absolute;
}
#RecommendedSection .react-stars{
  position:absolute !important;
  bottom:0px;
  left:0px;
  height:44px;
  direction:ltr;
}
#ratesDoneSection{
  width: fit-content !important;
  margin: auto !important;
  text-align: center !important;
  padding-top:15%;
}
#ratesDoneSection div{
  width: 150px;
  height: 150px;
  padding: 20px;
  object-fit: cover;
  margin: auto;
  border-radius: 50%;
  overflow: hidden;
  box-shadow: 0px 3px 9px 0px var(--second-color);
  margin-block: 25px;
}
.info-img-box{
  width: 150px;
  height: 150px;
  padding: 0px;
  object-fit: cover;
  display: grid;
}
#infoSection .order-img,
#infoSection .store-img,
#ratesDoneSection .ratesDone-img{
  max-width: 60%;
  max-height: 60%;
  margin: auto;
  background-color:white;
}


.feedback{
  color: var(--main-color);
    font-family: 'Font Awesome 6 Brands';
    font-size: 12px;
    margin-block-start: -17px;
}
.css-13cymwt-control {
  height: 58px;
}
.card-header {
  background-color: white;
}
.form-control:focus{
  border-color: var(--main-color) !important;
  outline: 0;
  box-shadow: 0 0 0 .25rem var(--light-color) !important;
}
.card-img{
  width:100px !important;
  margin: auto;
}
`
