import React from "react";
import { PuffLoader } from "react-spinners";

let FullScreenLoadingFirstLoad = ({ height, paddingTop }) => {

  return (
    <div
      style={{ height: height, paddingTop: paddingTop }}
      className="d-flex align-items-center justify-content-center"
    >
      <PuffLoader loading={PuffLoader} color='#000' size={55} />
    </div>
  );
};
export default FullScreenLoadingFirstLoad;
