import { configureStore } from '@reduxjs/toolkit';
import storeInfoSlice from './slices/storeInfoSlice';
import categorySlice from './slices/categorySlice';
import cartSlice from './slices/cartSlice';
import helperSlice from './slices/helperSlice';
import rateSlice from './slices/rateSlice';
import orderSlice from './slices/orderSlice';

const store = configureStore({
    reducer: {
        storeInfo: storeInfoSlice,
        category: categorySlice,
        cart: cartSlice,
        helperSlice: helperSlice,
        rate:rateSlice,
        order:orderSlice
    },
});

export default store;