import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { t } from 'i18next'
import { Link, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';
import { add } from "../../slices/cartSlice";
import Toast from '../../components/Toast/Toast'
import { FloatingLabel, Form as BootstrapForm, Spinner } from 'react-bootstrap'


export default function AddItemToCartModal(props) {
  const { id, type } = useParams();
  const { selectedProduct } = useSelector((state) => state.cart);
  const [note, setNote] = useState("");
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState();
  const { items } = useSelector((state) => state.cart)


  function checkIsExists() {
    return items.some(function (obj) {
      return obj.id === selectedProduct.id;
    });
  }
  const addToCart = async (selectedProduct, note) => {
    setIsLoading(true)
    let data = {
      id: selectedProduct.id,
      name: selectedProduct.name,
      image: selectedProduct.image,
      quantity: 1,
      price: !selectedProduct.discounted_price ? selectedProduct.price : selectedProduct.discounted_price,
      note: note,
    };
    dispatch(add(data))
    Toast.SuccessToast(t('addToCartSuccess'));
    props.onHide();
    setIsLoading(false)
  };

  return (
    checkIsExists() ?
      <Modal
        show={selectedProduct && props.show}
        size="lg"
        centered
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            {t('addToCart')}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className='text-center'>
          {t('existProduct')}
          <Link to={`/${id}/${type}/cart`}>
            <i className="bi bi-basket fs-3 text-center mx-2" style={{ color: 'var(--main-color)' }}></i>
          </Link>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={props.onHide} variant='secondary'>{t('close')}</Button>
        </Modal.Footer>
      </Modal> :
      <Modal
        show={selectedProduct && props.show}
        size="lg"
        centered
      >
        <Modal.Header >
          <Modal.Title id="contained-modal-title-vcenter">
            {t('addToCart')}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FloatingLabel controlId="note" label={t('note')} className={'mb-3'}>
            <BootstrapForm.Control as="textarea" style={{ height: '100px' }} type="text" name="note" onChange={(event) => setNote(event.target.value)} />
          </FloatingLabel>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={props.onHide} variant='secondary'>{t('close')}</Button>
          <Button
            onClick={() => {
              addToCart(
                selectedProduct,
                note,
              );
            }}
            variant='primary'>{isLoading ? <Spinner size="sm" /> : t('add')}</Button>
        </Modal.Footer>
      </Modal>
  );
}

