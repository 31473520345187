import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { t } from 'i18next'
import { useDispatch , useSelector} from "react-redux";
import { removefromCart} from "../../slices/cartSlice";
import Toast from '../../components/Toast/Toast'
import { useState } from 'react';
import { Spinner } from 'react-bootstrap';

export default function DeleteModal(props) {
  
  const dispatch = useDispatch();
  const { deleteProduct} = useSelector((state) => state.cart);
  const [isLoading, setIsLoading] = useState(false);

  const handledelete = (product) =>{
    setIsLoading(true)
    dispatch(removefromCart(product))
    Toast.SuccessToast(t('deletedSuccess'));
    props.onHide();
    setIsLoading(false)
  }
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
          {t('deleteProduct')}
        </Modal.Title>
      </Modal.Header>
      <Modal.Footer>
        <Button onClick={props.onHide} variant='secondary'>{t('close')}</Button>
        <Button onClick={() => handledelete(deleteProduct)} variant='danger'>{isLoading ? <Spinner size="sm" /> :t('delete')}</Button>
      </Modal.Footer>
    </Modal>
  );
}

