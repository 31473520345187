import React from 'react';
import ReactDOM from 'react-dom/client';
// import './assets/css/index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import store from './store';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { I18nextProvider } from 'react-i18next';
import i18n from './helpers/i18n';
import { ToastContainer } from "react-toastify";
import "bootstrap-icons/font/bootstrap-icons.css";
import ThemeManager from './helpers/ThemeManger';

if (i18n.language === "ar") {
  import("bootstrap/dist/css/bootstrap.rtl.min.css");
  import('./assets/css/indexRtl.css');
} else {
  import("bootstrap/dist/css/bootstrap.min.css");
  import('./assets/css/indexLtr.css');
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <Router>
        <ThemeManager>
          <I18nextProvider i18n={i18n}>
            <App />
            <ToastContainer />
          </I18nextProvider>
        </ThemeManager>
      </Router>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
