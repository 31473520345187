
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    selectedCategoryId: null,
};

const helperSlice = createSlice({
    name: "helperSlice",
    initialState,
    reducers: {
        setSelectedCategoryId: (state, payload) => {
            state.selectedCategoryId = payload.payload
        },
    }
});

export const { setSelectedCategoryId } = helperSlice.actions
export default helperSlice.reducer;
