import React from 'react'
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import logo from '../../assets/imgs/logo.png';
import { t } from 'i18next'
import akwadLogo from '../../assets/imgs/Logo2.png'
import { selectStoreInfo } from "../../slices/storeInfoSlice";
import env from '../../env';
import { useSelector } from 'react-redux';
import ReactStars from "react-rating-stars-component";
import LanguageSwitcher from '../others/LanguageSwitcher';

export default function RatesLayout({ children }) {

    const storeInfo = useSelector(selectStoreInfo);

    return (
        <>
            <Navbar collapseOnSelect expand="lg" className="bg-body-tertiary p-0 px-2 layout" id="navbar">
                <Navbar.Brand href="#home" >
                    <Nav.Item>
                        <img className='rounded-circle' src={storeInfo?.image ? env.API_LINK_STORAGE + storeInfo?.image : logo} width='50px' alt='logo' />
                    </Nav.Item>
                </Navbar.Brand>
                {storeInfo?.rate && <ReactStars count={5} size={20} value={storeInfo?.rate} activeColor="var(--main-color)" />}

                <Navbar id="responsive-navbar-nav">
                    <Nav className="me-auto">
                    </Nav>
                    <Nav>
                        <Nav.Item eventKey={2} className='p-0'><i className="bi bi-info-circle-fill fs-3 px-2" style={{ color: 'var(--main-color)' }}></i></Nav.Item>
                    </Nav>
                    <LanguageSwitcher />
                </Navbar>
            </Navbar>
            <Container >
                {children}
            </Container>
            <div className='footer mx-auto'>
                {t('rights')}<a href='https://akwad4it.com' target="_blank" rel="noreferrer" ><img alt="logo" src={akwadLogo} width='20px' className="mx-1" /></a>
            </div>
        </>
    )
}

