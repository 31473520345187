import { Col, Row } from "react-bootstrap";

const ErrorPage = () => {
  return (
    <>
      <style>
        .error
        {`{
          position: absolute;
          height:100vh;
          width: 100vw;
          top:0;
          left:0;
          overflow:hidden;
          background-color:#fff;
          z-index:1000;
        }`}
        .notfound{" "}
        {`{
    position: absolute;
    left: 50%;
    top: 50%;
    max-width: 520px;
    width: 100%;
    text-align: center;
    -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
  }`}
        .notfound .notfound-bg{" "}
        {`{
    position: absolute;
    left: 0px;
    right: 0px;
    top: 50%;
    -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
            transform: translateY(-50%);
    z-index: -1;
  }`}
        .notfound .notfound-bg div{" "}
        {`{
    width: 100%;
    background: #fff;
    border-radius: 90px;
    height: 125px;
  }`}
        .notfound .notfound-bg div:nth-child(1){" "}
        {`{
    -webkit-box-shadow: 5px 5px 0px 0px #f3f3f3;
            box-shadow: 5px 5px 0px 0px #f3f3f3;
  }`}
        .notfound .notfound-bg div:nth-child(2){" "}
        {`{
    -webkit-transform: scale(1.3);
        -ms-transform: scale(1.3);
            transform: scale(1.3);
    -webkit-box-shadow: 5px 5px 0px 0px #f3f3f3;
            box-shadow: 5px 5px 0px 0px #f3f3f3;
    position: relative;
    z-index: 10;
  }`}
        .notfound .notfound-bg div:nth-child(3){" "}
        {`{
    -webkit-box-shadow: 5px 5px 0px 0px #f3f3f3;
            box-shadow: 5px 5px 0px 0px #f3f3f3;
    position: relative;
    z-index: 90;
  }`}
        .notfound h1{" "}
        {`{
    font-family: 'Quicksand', sans-serif;
    font-size: 86px;
    text-transform: uppercase;
    font-weight: 700;
    margin-top: 0;
    margin-bottom: 8px;
    color: #151515;
  }`}
        .notfound h2{" "}
        {`{
    font-family: 'Quicksand', sans-serif;
    font-size: 26px;
    margin: 0;
    font-weight: 700;
    color: #151515;
  }`}
        .social-media{" "}
        {`{
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  margin-top:10px;
  border-top: 1px solid #ffffff38;
}`}
        .social-media a{" "}
        {`{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 10px;
  background:var(--main-color);
  border: 1px solid var(--main-color);
  color: #f2f2f2;
  transition: 0.3s;
  text-decoration: none;
}`}
        .social-media a:hover{" "}
        {`{
  background: var(--second-color);
  border-color:var(--second-color);
  color: #f2f2f2;
}`}
      </style>
      <div className="error">
        <div className="notfound">
          <div className="notfound-bg">
            <div></div>
            <div></div>
            <div></div>
          </div>
          <h1>oops!</h1>
          <h2>Error 404</h2>
          <Row>
            <Col lg={12} className="social-media">
            <a target="_blank" href="https://www.facebook.com/akwad4it/" rel="noreferrer">
            <i className="bi bi-facebook"></i>
            </a>
            <a target="_blank" href="https://www.instagram.com/akwad4it/" rel="noreferrer">
            <i className="bi bi-instagram"></i>            
            </a>
            <a target="_blank" href="https://wa.me/+9647834825725" rel="noreferrer">
            <i className="bi bi-whatsapp"></i>            
            </a>
            <a target="_blank" href="https://t.me/akwad4it" rel="noreferrer">
            <i className="bi bi-telegram"></i>            
            </a>
            <a target="_blank" href="tel:+9647834825725" rel="noreferrer">
            <i className="bi bi-telephone-fill"></i>            
            </a>
            <a target="_blank" href="https://www.tiktok.com/@akwad4it" rel="noreferrer">
            <i className="bi bi-tiktok"></i>            
            </a>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

export default ErrorPage;
