import { createSlice } from "@reduxjs/toolkit";
import { cookiesService } from "../services/cookies.service";

const initialState = {
  items: [],
  selectedProduct: 0,
  rate: 0
};
const orderKey = 'order'
const orderSlice = createSlice({
  name: "order",
  initialState,
  reducers: {
    getAll: (state) => {
      state.items = cookiesService.get(orderKey) ?? [];
    },
    removeOrder: (state) => {
      cookiesService.remove(orderKey)
    },
    setSelectedProduct: (state, { payload }) => {
      state.selectedProduct = payload;
    },
    setSelectedProductRate: (state, { payload }) => {
      state.rate = payload;
    }
  }
});

export const { getAll, removeOrder, setSelectedProduct, setSelectedProductRate} = orderSlice.actions
export default orderSlice.reducer;
