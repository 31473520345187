import Cookies from "js-cookie";

function get(key) {
    let data = Cookies.get(key);
    if (data) {
        return JSON.parse(data);
    } else {
        return null;
    }
}

function add(key, data) {
    Cookies.set(key, JSON.stringify(data));
}

function edit(key, data) {
    Cookies.set(key, JSON.stringify(data));
}

function remove(key) {
    Cookies.remove(key);
}

export const cookiesService = {
    get, add, remove, edit
}