import { createApiSlice } from '../helpers/createApiSlice';
import { appService } from '../services/app.service';

const categorySlice = createApiSlice({
    name: 'category',
    initialState: {
        data: null,
        isLoading: false,
        error: null,
    },
    fetchThunk: appService.getCategories,
});

export const selectCategory = (state) => state.category.data;
export const selectCategoryLoading = (state) => state.category.isLoading;
export const selectCategoryInfoError = (state) => state.category.error;
export default categorySlice.reducer;