import React, { useState } from "react";
import MainLayout from '../components/layouts/MainLayout'
import { useDispatch, useSelector } from "react-redux";
import { selectStoreInfo ,selectStoreInfoLoading } from "../slices/storeInfoSlice";
import itemtImg from '../assets/imgs/Logo2.png';
import orditlogo from '../assets/imgs/logo.png'
import env from "../env";
import FullScreenLoading from '../components/Loading/FullScreenLoading'
import { t } from 'i18next'
import { useParams } from "react-router-dom";


const StoreInfoPage = () => {
  
  
  const dispatch = useDispatch();
  const { id, name } = useParams();

  const [storeStyle, setStoreStyle] = useState("none");
  const [orderStyle, setOrderStyle] = useState("orderBasha-details");
  const [zIndexStore, setZIndexStore] = useState('zIndex')
  const [zIndexOrder, setZIndexOrder] = useState('setZIndex');
  const [isLoading, setIsLoading] = useState(false);

  const handleStoreDetails = () => {
    setStoreStyle("store-details");
    setOrderStyle('none')
    setZIndexStore('setZIndex')
    setZIndexOrder('zIndex')
    setIsLoading(true)
  }

  const handleAssistDetails = () => {
    setStoreStyle("none");
    setOrderStyle('orderBasha-details')
    setZIndexStore('zIndex')
    setZIndexOrder('setZIndex')
  }

  const storeInfo = useSelector(selectStoreInfo);
  const storeLoading = useSelector(selectStoreInfoLoading)

  return (
    <MainLayout>
      {storeLoading ? (
        <>
          <FullScreenLoading height={"100vh"} />
        </>
      ) : (
        <>
          <section id='infoSection'>
            <div className="container">
              <div className='images-box'>
                <div height='150px' className={zIndexStore} onClick={() => handleStoreDetails()} >
                  <div className="info-img-box">
                    <img src={storeInfo?.image ? env.API_LINK_STORAGE + storeInfo?.image : itemtImg} className="store-img" />
                  </div>
                </div>
                <div className={zIndexOrder} height='150px' onClick={() => handleAssistDetails()}>
                  <div className="info-img-box">
                    <img src={orditlogo} width='150px' height='150px' alt="orderBashs-logo" className="order-img" />
                  </div>
                </div>
              </div>

              <div className={storeStyle} >
                <h4>{storeInfo?.type}</h4>
                <div className="store-info">
                  <h1 className="text-center">{storeInfo?.name}</h1>
                  <p className="text-center">{storeInfo?.description}</p>
                  <p className="text-center">{storeInfo?.address}</p>
                  <p className="text-center">{t('contactUs')}
                    <a target="_blank" href={'tel:+' + storeInfo?.phone} rel="noreferrer" className="mx-2">
                      <i className="bi bi-telephone-fill"></i>            
                    </a> 
                  </p>
                </div>
              </div>

              <div className={orderStyle}>
                <h2>OrdIt</h2>
                <p>{t('orditInfo')}
                </p>

                <a href='https://admin-ordit.akwad4it.com/register' style={{ color: 'var(--main-color)' }}>
                 {t('createAcount')}
                </a>
                  <p className="mt-2">{t('contactUs')} </p>
                  <div className="orderBasha-social-media">
                    <a target="_blank" href="https://www.facebook.com/akwad4it/" rel="noreferrer">
                      <i className="bi bi-facebook"></i>
                    </a>
                    <a target="_blank" href="https://www.instagram.com/akwad4it/" rel="noreferrer">
                      <i className="bi bi-instagram"></i>
                    </a>
                    <a target="_blank" href="https://wa.me/+9647834825725" rel="noreferrer">
                      <i className="bi bi-whatsapp"></i>
                    </a>
                    <a target="_blank" href="https://t.me/akwad4it" rel="noreferrer">
                      <i className="bi bi-telegram"></i>
                    </a>
                    <a target="_blank" href="tel:+9647834825725" rel="noreferrer">
                      <i className="bi bi-phone"></i>
                    </a>
                    <a target="_blank" href="https://www.tiktok.com/@akwad4it" rel="noreferrer">
                      <i className="bi bi-tiktok"></i>
                    </a>
                  </div>
              </div>
            </div>
          </section>
        </>
      )}
    </MainLayout>
 
  
)};

export default StoreInfoPage;
