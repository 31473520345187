import React from "react";
import { PuffLoader } from "react-spinners";

let FullScreenLoading = ({ height, paddingTop }) => {

  return (
    <div
      style={{ height: height, paddingTop: paddingTop }}
      className="d-flex align-items-center justify-content-center"
    >
      <PuffLoader loading={PuffLoader} color='var(--main-color)' size={55} />
    </div>
  );
};
export default FullScreenLoading;
